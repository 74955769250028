import { useEffect } from 'react';
import { hookstate, useHookstate } from '@hookstate/core';
import { GetBranchy, AddProject, GetListProjects } from '../../services/seenapse';
import { parseProxyData } from '../../app/branchy/utils/cardUtils';
import { updateBranch} from '../../app/branchy/utils/updateBranch'
import { CreateBranchy } from '../../services/seenapse';
import useNavigation from '../useNavigation';
import { getUid } from '../../utils/Utils';

const userUid = getUid()

const initialState = hookstate({
  currentProject: [],
  submiting: false,
  isLoading: false,
  projects: [],
  projectsDeleted: []
});

export default function useBranchyStore() {
  const {
    projectsDeleted,
    currentProject,
    submiting,
    projects,
    isLoading
  } = useHookstate(initialState);

  const { onNavigate, searchParams, pathname } = useNavigation()

  const getListProjects = () => {
    onLoadingProject(true);
    GetListProjects(userUid)
      .then(({ data: { data } }) => {
        const paths = pathname.split('/').filter(Boolean)
        const filteredProjects = data.filter(project => project.status === 1)
        const filteredProjectsDeleted = data.filter(project => project.status === 0)

        const sortedRecentDeleted = filteredProjectsDeleted.sort((a, b) => {
          const aDate = new Date(a.updated_at).getTime();
          const bDate = new Date(b.updated_at).getTime();
          return aDate - bDate;
        }).slice(-5);

        projectsDeleted.set(sortedRecentDeleted);
        projects.set(filteredProjects);

        if (
          !searchParams.projectUid
          && paths[2] !== 'shared'
          && searchParams.project !== 'shared'
        ) {
          if (filteredProjects.length > 0) {
            return getBranchy(filteredProjects[0].uid)
          } else {
            return createProject('Welcome to Seenapse')
          }
        }
      })
      .catch(error => console.error(error))
      .finally(() => {
        onLoadingProject(false)
      });
  };

  const getBranchy = (projectUid) => {
    GetBranchy(projectUid)
      .then(({ data }) => {
        const parseData = data.map((conversation) => ({
          ...conversation,
          options: !conversation.parent_chat_id ? [
            { label: 'Brainstorm', action: 'Brainstorm' },
            { label: 'Brief', action: 'Brief' },
          ] : []
        }));
        onSetCurrentProject(parseData);
        if (searchParams.projectUid !== projectUid) {
          onHandleChat(projectUid);
        }
      })
      .catch((error) => {
        console.error('Error fetching initial conversation', error);
      });
  }

  // Function to create a new project
  const createProject = (projectName) => {
    const userLang = navigator.language || navigator.userLanguage;
    const lang = userLang.split('-')[0] === 'es' ? 'es' : userLang.split('-')[0] === 'pt' ? 'pt' : 'en';
    onSubmitingProject(true)
    AddProject({
      project: {
        user_id: userUid,
        description: projectName,
        lang,
      },
    })
      .then(({ data }) => {
        const parseProjects = parseProxyData(projects.get())
        const newProject = {
          id: 0,
          status: 1,
          user_id: userUid,
          uid: data.data.uid,
          description: projectName,
          updated_at: new Date().toString(),
          inserted_at: new Date().toString()
        }
        onSetProjects([newProject, ...parseProjects])
        getBranchy(data.data.uid);
      })
      .catch((error) => {
        console.error('Error creating initial conversation:', error);
      })
      .finally(() => {
        onSubmitingProject(false);
      })
  };

  const onHandleChat = (projectUid) => {
    onNavigate('/ai/project/chat',{
      searchParams: { projectUid }
    })
  };

  // Initial API call for history
  useEffect(() => {
    if (searchParams.projectUid) {
      getBranchy(searchParams.projectUid);
    }
  }, [searchParams.projectUid]);

  // Function to send message to API
  const onMessageToApi = async (
    userInput,
    branchConversation
  ) => {
    const parseCurrentProject = parseProxyData(currentProject.get())
    if (!parseCurrentProject.length === 0) return;
    const params = {
      uid: userUid,
      user_id: userUid,
      session: branchConversation.session,
      content: userInput,
      parent_chat_id: branchConversation.id,
    }

    const updatedProjects = updateBranch({
      data: parseCurrentProject,
      id: branchConversation.id,
      content: userInput
    })

    onSetCurrentProject(updatedProjects)

    CreateBranchy(params)
      .then(({ data }) => {
        if (data) {
          getBranchy(parseCurrentProject[0].session)
        }
      })
      .catch((error) => {
        console.error('Error sending message:', error);
      });
  };

  // Function to rename a project
  const renameProject = (project, newName) => {
    const parsedProjects = parseProxyData(projects.get());
    const filteredProjects = parsedProjects.map(p => (p.name === project.name ? { ...p, name: newName } : p));
    onSetProjects(filteredProjects);
    onSetCurrentProject({ ...project, name: newName });
  };

  // Function to switch to a different project
  const switchProject = (newProject) => {
    const parsedProject = parseProxyData(newProject);
    onHandleChat(parsedProject.uid);
  }

  const onDeletePinnProject = (projectUid) => {
    const parseProjects = parseProxyData(projects.get())
    const filteredProjects = parseProjects.filter(p => p.uid !== projectUid);
    onSetProjects(filteredProjects)
    getBranchy(filteredProjects[0].uid);
  }

  const onLoadingProject = (loading) => isLoading.set(loading);

  const onSetCurrentProject = (project) => currentProject.set(project);

  const onSetProjects = (newProjects = []) => projects.set(newProjects);

  const onSubmitingProject = (loading) => submiting.set(loading);
  const parseFinalProjects = parseProxyData(projects.get())
  const projectName = projects ? parseFinalProjects.filter(p => p.uid === searchParams.projectUid)[0]?.description : '';
  return {
    projects: parseProxyData(projects.get()),
    currentProject: parseProxyData(currentProject.get()),
    projectsDeleted: parseProxyData(projectsDeleted.get()) || [],
    submiting: submiting.get(),
    loadingProject: isLoading.get(),
    projectName: projectName,
    getListProjects,
    renameProject,
    getBranchy,
    switchProject,
    createProject,
    onMessageToApi,
    onSubmitingProject,
    onSetCurrentProject,
    onDeletePinnProject
  };
}
